const ShapeLogo = ({ ...props }) => (

    <svg {...props} width="76" height="24" viewBox="0 0 76 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_856_41)">
            <path d="M12.0621 2C17.6055 2 22.0701 6.46939 22.0569 12.0204C22.0427 17.7664 17.2389 22.1402 11.7666 21.9962C6.37945 21.8551 1.8987 17.3327 2.00193 11.7249C2.10043 6.40403 6.56982 2 12.0621 2Z" fill="currentColor" />
            <path d="M56.8383 16.3032V21.8987H54.5671V8.07188H56.79C56.807 8.38442 56.8241 8.69128 56.844 9.06634C56.9718 8.94416 57.058 8.86555 57.1414 8.7822C58.2078 7.71292 59.7535 7.48372 61.3541 8.15901C63.3979 9.02087 64.5856 11.0732 64.3308 13.3027C64.0922 15.3939 62.4272 17.1849 60.2943 17.4889C59.0744 17.6632 58.3252 17.7465 56.8392 16.3032H56.8383ZM59.4693 10.0078C58.079 10.003 57.04 11.1187 57.022 12.636C57.004 14.1599 58.0601 15.3485 59.4381 15.3542C60.8588 15.3598 62.0436 14.1428 62.0512 12.6691C62.0578 11.238 60.8692 10.0125 59.4693 10.0078Z" fill="currentColor" />
            <path d="M51.4265 9.09571C51.401 8.73391 51.3801 8.42326 51.3555 8.07852H53.6096V17.2702H51.3669C51.3252 16.9624 51.2835 16.6574 51.2305 16.2691C50.6651 16.9614 50.0295 17.4672 49.1648 17.5221C47.2242 17.6462 45.6748 16.8961 44.6178 15.2746C43.5712 13.6693 43.4756 11.9427 44.4009 10.2559C45.3281 8.56628 46.8227 7.74798 48.7595 7.78018C49.6526 7.79533 50.398 8.17986 51.0505 8.76612C51.1642 8.8684 51.2807 8.96785 51.4265 9.09476V9.09571ZM51.1765 12.6777C51.1822 11.1708 50.0921 9.96894 48.7216 9.97178C47.3275 9.97367 46.1616 11.1926 46.1559 12.654C46.1502 14.1154 47.3009 15.3381 48.6979 15.3542C50.0807 15.3703 51.1708 14.194 51.1765 12.6777Z" fill="currentColor" />
            <path d="M34.6116 3.44055H36.8582V8.71687C36.9377 8.7055 36.9927 8.71403 37.0192 8.69035C38.1907 7.694 39.512 7.47995 40.8995 8.09273C42.2453 8.68656 43.0399 9.77384 43.0816 11.2617C43.1375 13.2469 43.0958 15.2358 43.0958 17.2607H40.8275C40.819 17.1016 40.8019 16.9358 40.8019 16.7691C40.8 15.2538 40.7669 13.7375 40.8114 12.224C40.8455 11.0666 40.0139 10.0949 38.8367 10.0779C37.702 10.0618 36.8771 10.9871 36.8752 12.2297C36.8733 13.7289 36.8752 15.2282 36.8752 16.7275V17.2683H34.6126V3.44055H34.6116Z" fill="currentColor" />
            <path d="M74.1267 13.459C72.904 13.459 71.7883 13.459 70.6726 13.459C69.5569 13.459 68.4678 13.459 67.3189 13.459C67.515 14.2887 67.9734 14.8512 68.732 15.1259C69.556 15.4242 70.3554 15.3059 70.9454 14.6438C71.3015 14.2442 71.6207 14.2006 72.0829 14.3445C72.5886 14.5027 73.1124 14.5993 73.7015 14.7395C72.9864 16.1261 71.9834 17.0817 70.4908 17.4293C68.3892 17.918 66.3396 16.8989 65.3509 14.9128C64.2314 12.6634 64.9644 9.84202 67.0159 8.50944C69.7549 6.72983 73.3435 8.30771 73.9866 11.5961C74.1021 12.1861 74.0803 12.8027 74.1258 13.459H74.1267ZM71.6917 11.7163C71.5014 10.5419 70.3913 9.66017 69.2747 9.77382C68.2604 9.87706 67.2659 10.863 67.318 11.7163H71.6917Z" fill="currentColor" />
            <path d="M33.8426 9.49443C33.1692 9.85527 32.5356 10.1953 31.8925 10.54C31.1812 9.78329 30.3383 9.4859 29.3543 9.7795C29.091 9.85811 28.8163 10.0551 28.6544 10.2767C28.4053 10.6186 28.5331 10.9965 28.9224 11.1755C29.2188 11.3119 29.555 11.3593 29.86 11.4796C30.8071 11.8508 31.8186 12.1226 32.6767 12.6435C34.2271 13.5859 34.2404 15.4744 32.8046 16.5882C31.1329 17.8848 28.123 17.738 26.584 16.2738C26.3595 16.0598 26.1805 15.7974 25.9305 15.4972C26.6465 15.2159 27.2905 14.9488 27.9478 14.7196C28.0435 14.6865 28.2225 14.8143 28.3314 14.9043C29.0692 15.519 29.896 15.6885 30.8071 15.3996C31.1642 15.2869 31.4691 15.0984 31.4862 14.6542C31.5042 14.1949 31.1954 14.0064 30.8355 13.9022C30.2625 13.7356 29.6621 13.6532 29.1023 13.4543C28.4706 13.2298 27.8171 12.9949 27.2668 12.6237C25.9229 11.7173 25.8178 9.9604 26.9751 8.82672C28.7311 7.10772 32.3727 7.43921 33.8397 9.49348L33.8426 9.49443Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_856_41">
                <rect width="72.1267" height="20" fill="transparent" transform="translate(2 2)" />
            </clipPath>
        </defs>
    </svg>

)

export default ShapeLogo
