import { Await, useLoaderData } from '@remix-run/react'
import { PublicKey } from '@solana/web3.js'
import {
	type ActionFunctionArgs,
	type LoaderFunction,
	type LoaderFunctionArgs,
	defer,
	json,
	redirect,
} from '@remix-run/node'
import { Suspense } from 'react'
import Home from '#app/components/home/home.tsx'
import { getNFTFromCache } from '#app/server/db.server.ts'
import {
	type artist,
	type collection,
	type collectionAndNFTs,
	type nft,
} from '#types/loreTypes.tsx'
import { GeneralErrorBoundary } from '#app/components/common/errorBoundary.tsx'
import { chainOptions } from '#app/utils/misc.js'

export const loader: LoaderFunction = async ({
	request,
	params,
}: LoaderFunctionArgs) => {
	if (import.meta.env.VITE_HOME_NFT_URLS) {
		const homeNFTs = getFixedNFTs()
		return defer({
			homeNFTs,
			atomicSignUrl: import.meta.env.VITE_ATOMICSIGN_URL,
		})
	} else {
		const homeNFTs = getRandomNFTs()
		return defer({
			homeNFTs,
			atomicSignUrl: import.meta.env.VITE_ATOMICSIGN_URL,
		})
	}
}

async function getFixedNFTs() {
	try {
		let artists: any[] = []
		let collections: any[] = []
		let nfts: any[] = []

		if (!import.meta.env.VITE_HOME_NFT_URLS)
			return { artists, collections, nfts }

		const nftURLs = import.meta.env.VITE_HOME_NFT_URLS.split(',')

		await Promise.all(
			nftURLs.map(async (nftURL: string) => {
				const urlComponents = nftURL.split('/')
				const chain = urlComponents[4]
				let contractAddress
				let tokenId
				if (chain == 'solana') {
					tokenId = urlComponents[5]
				} else {
					contractAddress = urlComponents[5]
					tokenId = urlComponents[6]
				}
				const nftData = await getNFTFromCache(chain, contractAddress, tokenId)
				if (nftData) {
					nfts.push(nftData)
				}
			}),
		)

		return { artists, collections, nfts }
	} catch (e) {
		console.log(e)
		// If the fixed NFTs list is misconfigured, default to random NFTs
		const { artists, collections, nfts } = await getRandomNFTs()
		return { artists, collections, nfts }
	}
}

async function getRandomNFTs() {
	let artists: artist[] = []
	let collections: collection[] = []
	let nfts: nft[] = []

	try {
		const collectionsResponse = await fetch(
			`${import.meta.env.VITE_MARIADB_API_ENDPOINT}/api/v1/collections/`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ limit: 10, random: true }),
			},
		)
		let artistsAndCollections = (await collectionsResponse.json()) as {
			artists: artist[]
			collections: collection[]
		}

		artists = artistsAndCollections.artists
		collections = artistsAndCollections.collections

		await Promise.all(
			collections.map(async collection => {
				const options = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						limit: 1,
						offset: 0,
						random: true,
					}),
				}
				const nftsResponse = await fetch(
					`${import.meta.env.VITE_MARIADB_API_ENDPOINT}/api/v1/collection/nfts/${collection.id}`,
					options,
				)

				const collectionAndNftsData =
					(await nftsResponse.json()) as collectionAndNFTs
				if (collectionAndNftsData.nfts && collectionAndNftsData.nfts[0]) {
					let candidateNFT = collectionAndNftsData.nfts[0]
					candidateNFT.collection = collection.id
					candidateNFT.collectionName = collection.collectionName
						? collection.collectionName
						: collection.name
							? collection.name
							: null
					candidateNFT.chain = collection.collectionNetwork
						? collection.collectionNetwork == 'eth-mainnet'
							? 'ethereum'
							: 'solana'
						: ''
					candidateNFT.contract.address = collection.collectionAddress
					nfts.push(candidateNFT)
				}
			}),
		)

		return { artists, collections, nfts }
	} catch (e) {
		console.log(e)
		return { artists, collections, nfts }
	}
}

function getStringsFromLink(link: string) {
	const url = new URL(link)
	const paths = url.pathname.split('/')
	let contractAddressFromLink = ''
	let tokenIdFromLink = ''
	let tokenAddressFromLink = ''
	let error = ''
	let chainFromLink = ''

	if (url.hostname == 'exchange.art') {
		const lastPath = paths[paths.length - 1]
		if (lastPath.indexOf('-') > -1) {
			const splitLastPath = lastPath.split('-')
			contractAddressFromLink = splitLastPath[0]
			tokenIdFromLink = splitLastPath[1]
			chainFromLink = 'ethereum'
		} else {
			const isValidAddress = PublicKey.isOnCurve(lastPath)

			if (isValidAddress) {
				tokenAddressFromLink = lastPath
				chainFromLink = 'solana'
			} else {
				error = "Sorry, this doesn't appear to be a valid exchange.art url"
			}
		}
	} else if (url.hostname == 'solana.fm') {
		chainFromLink = 'solana'
		const lastPath = paths[paths.length - 1]
		const isValidAddress = PublicKey.isOnCurve(lastPath)
		if (isValidAddress) {
			tokenAddressFromLink = lastPath
		} else {
			error = "Sorry, this doesn't appear to be a valid solana.fm url"
		}
	} else if (url.hostname == 'opensea.io') {
		if (
			paths[2] === 'ethereum' ||
			paths[2] === 'base' ||
			paths[2] === 'matic'
		) {
			if (paths[2] === 'ethereum') {
				chainFromLink = 'ethereum'
			} else if (paths[2] === 'base') {
				chainFromLink = 'base'
			} else if (paths[2] === 'matic') {
				chainFromLink = 'polygon'
			}
			contractAddressFromLink = paths[3]
			tokenIdFromLink = paths[4]
		} else if (paths[2] === 'solana') {
			chainFromLink = 'solana'
			tokenAddressFromLink = paths[3]
		} else {
			error = "Sorry, this doesn't appear to be a valid opensea url"
		}
	} else if (url.hostname == 'etherscan.io') {
		chainFromLink = 'ethereum'
		if (paths[1] === 'token') {
			contractAddressFromLink = paths[2]
			tokenIdFromLink = url.searchParams.get('a') || ''
		} else if (paths[1] == 'nft') {
			contractAddressFromLink = paths[2]
			tokenIdFromLink = paths[3]
		} else {
			error = "Sorry, this doesn't appear to be a valid etherscan url"
		}
	} else if (url.hostname == 'basescan.io' || url.hostname === 'basescan.org') {
		chainFromLink = 'base'
		if (paths[1] === 'token') {
			contractAddressFromLink = paths[2]
			tokenIdFromLink = url.searchParams.get('a') || ''
		} else if (paths[1] == 'nft') {
			contractAddressFromLink = paths[2]
			tokenIdFromLink = paths[3]
		} else {
			error = "Sorry, this doesn't appear to be a valid basescan url"
		}
	} else if (url.hostname == 'blur.io') {
		chainFromLink = 'ethereum'
		if (paths[1] !== 'asset') {
			error = "Sorry, this doesn't appear to be a valid blur url"
		} else {
			contractAddressFromLink = paths[2]
			tokenIdFromLink = paths[3]
		}
	} else if (url.hostname == 'zora.co') {
		if (paths[2].includes(':') && paths[3]) {
			const pathTwoSplit = paths[2].split(':')
			chainFromLink = pathTwoSplit[0]
			contractAddressFromLink = pathTwoSplit[1]
			tokenIdFromLink = paths[3]
		} else {
			error = "Sorry, this doesn't appear to be a valid zora url"
		}
	} else if (url.hostname == 'tzkt.io') {
		if (paths) {
			chainFromLink = 'tezos'
			contractAddressFromLink = paths[1]
			tokenIdFromLink = paths[3]
		} else {
			error = "Sorry, this doesn't appear to be a valid tzkt url"
		}
	} else if (url.hostname == 'objkt.com') {
		if (paths) {
			chainFromLink = 'tezos'
			contractAddressFromLink = paths[2]
			tokenIdFromLink = paths[3]
		} else {
			error = "Sorry, this doesn't appear to be a valid objt url"
		}
	} else if (url.hostname === 'shapescan.xyz') {
		chainFromLink = 'shape'
		if (paths[1] !== 'token' && paths[3] !== 'instance') {
			error = "Sorry, this doesn't appear to be a valid shapescan url"
		} else {
			contractAddressFromLink = paths[2]
			tokenIdFromLink = paths[4]
		}
	} else if (url.hostname === 'highlight.xyz') {
		const chainIndex = paths[2].indexOf(':')
		if (paths[1] !== 'mint' && paths[3] !== 't' && chainIndex <= 0) {
			error = "Sorry, this doesn't appear to be a valid highlight url"
		} else {
			const chainFromPath = paths[2].slice(0, chainIndex)
			if (chainFromPath && chainFromPath.length > 0 && chainOptions.find(option => option.slug === chainFromPath)) {
				chainFromLink = chainFromPath
				const truncated = paths[2].slice(chainIndex + 1)
				if (truncated.includes(':')) {
					const indexOfChar = truncated.indexOf(':');
					contractAddressFromLink = truncated.slice(0, indexOfChar)
				} else {
					contractAddressFromLink = truncated;
				}
				tokenIdFromLink = paths[4]
			} else {
				error = `Sorry we don't support this chain yet: ${chainFromPath}`
			}
		}
	} else {
		error =
			'Invalid URL. Currently, we only accept links to the marketplaces listed below.'
	}
	console.log('Link return vals', {
		contractAddressFromLink,
		tokenIdFromLink,
		tokenAddressFromLink,
		chainFromLink,
		error,
	})
	return {
		contractAddressFromLink,
		tokenIdFromLink,
		tokenAddressFromLink,
		chainFromLink,
		error,
	}
}

function isValidContractAddress(contractAddress: string) {
	if (contractAddress === '') {
		return false
	}
	return true
}

function isValidTokenId(tokenId: string) {
	if (tokenId === '' || isNaN(Number(tokenId))) {
		return false
	}
	return true
}

function isValidTokenAddress(tokenAddress: string) {
	if (tokenAddress === '') {
		return false
	}
	try {
		const isValidAddress = PublicKey.isOnCurve(tokenAddress)
		if (isValidAddress) {
			return true
		} else {
			return false
		}
	} catch (e) {
		return false
	}
}

function isValidURL({ link }: { link: string }) {
	try {
		new URL(link)
	} catch (e) {
		return false
	}
	return true
}

export async function action({ request, params }: ActionFunctionArgs) {
	const form = await request.formData()
	const link = form.get('Search by URL') as string
	let contractAddress = form.get('Contract Address') as string
	let tokenAddress = form.get('Token Address') as string
	let tokenId = form.get('Token ID') as string
	let chainFromForm = form.get('chain') as string
	let chain: string = ''

	let errors = {
		contractAddress: '',
		tokenAddress: '',
		tokenId: '',
		chain: '',
		link: '',
		cid: '',
	}

	// Detect the NFT's chain
	if (link && link !== '') {
		const urlValidity = isValidURL({ link })
		if (!urlValidity) {
			return json({ link: 'Please enter a valid URL or search by token info' })
		}
		const {
			contractAddressFromLink,
			tokenIdFromLink,
			tokenAddressFromLink,
			chainFromLink,
			error,
		} = getStringsFromLink(link)
		if (chainFromLink && !error) {
			chain = chainFromLink
			contractAddress = contractAddressFromLink
			tokenId = tokenIdFromLink
			tokenAddress = tokenAddressFromLink
		} else {
			return json({ link: error })
		}
	} else if (chainFromForm) {
		chain = chainFromForm
	}

	if (chain === 'solana') {
		if (!isValidTokenAddress(tokenAddress)) {
			errors.tokenAddress = `Invalid Token Address - ${tokenAddress}`
		}
	} else {
		if (!isValidContractAddress(contractAddress)) {
			errors.contractAddress = `Invalid Contract Address - ${contractAddress}`
		}
		if (!isValidTokenId(tokenId)) {
			errors.tokenId = `Invalid Token ID - ${tokenId}`
		}
	}

	try {
		const nftData = await getNFTFromCache(
			chain,
			contractAddress,
			chain === 'solana' ? tokenAddress : tokenId,
		)
		if (nftData && (!nftData.success || nftData?.success === true)) {
			return redirect(
				`/nftProfile/${chain}${chain === 'solana' ? '' : `/${contractAddress}`}/${chain === 'solana' ? tokenAddress : tokenId}`,
				{
					status: 302,
				},
			)
		} else {
			if (link) {
				errors.link = 'Please enter a valid URL or search by token info'
			} else {
				if (tokenAddress)
					errors.tokenAddress = `Invalid Token Address - ${tokenAddress}`
				if (contractAddress)
					errors.contractAddress = `Invalid Contract Address - ${contractAddress}`
				if (tokenId) errors.tokenId = `Invalid Token ID - ${tokenId}`
			}
			return json(errors)
		}
	} catch (e) {
		console.error(e)
		return json({
			link: "This NFT doesn't exist or match our schema, please check and try again",
			tokenId:
				"This NFT doesn't exist or match our schema, please check and try again",
			contractAddress:
				"This NFT doesn't exist or match our schema, please check and try again",
			tokenAddress:
				"This NFT doesn't exist or match our schema, please check and try again",
		})
	}
}

export default function index() {
	const { homeNFTs, atomicSignUrl } = useLoaderData<{
		homeNFTs: {
			artists: artist[]
			collections: collection[]
			nfts: nft[]
		}
		atomicSignUrl: string
	}>()

	return (
		<Suspense
			fallback={
				<Home
					artists={[]}
					collections={[]}
					nfts={[]}
					atomicSignUrl={atomicSignUrl}
				/>
			}
		>
			<Await resolve={homeNFTs}>
				{homeNFTs => {
					const { artists, collections, nfts } = homeNFTs
					return (
						<Home
							artists={artists ? artists : []}
							collections={collections ? collections : []}
							nfts={nfts ? nfts : []}
							atomicSignUrl={atomicSignUrl}
						/>
					)
				}}
			</Await>
		</Suspense>
	)
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
