import { TypographyCaps, TypographyHeading } from '../typography'
import hero from '#images/hero-min.webp'
import Search from './search'

export default function Hero() {
	return (
		<>
			<div className="relative max-h-[420px] w-full sm:px-6 md:px-12">
				<img
					src={hero}
					alt="atomic form"
					className="max-h-[420px] w-full object-cover"
				/>
				<div className="absolute inset-0 sm:px-6 md:px-12">
					<div className="flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50 px-8 sm:px-16 md:px-20 lg:px-32">
						x
						<TypographyCaps
							size="medium"
							additionalClasses="text-textInactiveLight dark:text-textSecondaryDark"
						>
							Build your Lore™
						</TypographyCaps>
						<TypographyHeading
							size="large"
							additionalClasses="text-white dark:text-textPrimaryDark text-center sm:!text-7xl"
						>
							How will you enrich your NFT's story today?
						</TypographyHeading>
					</div>
				</div>
			</div>
			<div className="flex min-h-60 w-full max-w-[1440px] flex-row justify-center sm:px-6 sm:px-6 md:px-12 lg:px-24">
				<Search />
			</div>
		</>
	)
}
