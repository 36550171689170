const EthereumLogo = ({ ...props }) => (
	<svg
		{...props}
		width="96"
		height="24"
		viewBox="0 0 96 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M27.5092 13.8093C27.5065 13.9831 27.3645 14.1215 27.1916 14.1197H21.3437C21.4936 15.5742 22.5785 16.9046 24.1133 16.9046C25.1629 16.9046 25.9391 16.5055 26.5213 15.6629C26.576 15.5734 26.6721 15.5166 26.7771 15.5122C26.9332 15.5077 27.0637 15.6319 27.0682 15.7889C27.0682 15.7942 27.0682 15.7995 27.0682 15.8048C27.0682 15.8581 27.0532 15.9104 27.0241 15.9556C26.4331 16.9844 25.26 17.49 24.1133 17.49C22.1463 17.49 20.735 15.7162 20.735 13.827C20.735 11.9379 22.1375 10.1641 24.1133 10.1641C26.0891 10.1641 27.5004 11.9202 27.5092 13.8093ZM26.8829 13.5343C26.7594 12.0798 25.6481 10.7494 24.1133 10.7494C22.5785 10.7494 21.4936 12.0798 21.3437 13.5343H26.8829Z"
			fill="currentColor"
		/>
		<path
			d="M34.6187 10.3061C34.7775 10.3105 34.9054 10.4391 34.9098 10.5988C34.9142 10.7558 34.7907 10.887 34.6346 10.8915C34.6293 10.8915 34.624 10.8915 34.6187 10.8915H33.0486V17.091C33.0469 17.2524 32.9181 17.3819 32.7576 17.3837C32.5988 17.3792 32.4709 17.2506 32.4665 17.091V10.8915H30.9582C30.8021 10.8959 30.6715 10.7717 30.6671 10.6147C30.6671 10.6094 30.6671 10.6041 30.6671 10.5988C30.6689 10.4374 30.7977 10.3079 30.9582 10.3061H32.4665V8.04447C32.4665 7.88926 32.5794 7.75799 32.7311 7.73405C32.889 7.71276 33.0337 7.82451 33.0548 7.98327C33.0566 7.99746 33.0575 8.01254 33.0575 8.02673V10.3061H34.6187Z"
			fill="currentColor"
		/>
		<path
			d="M44.1887 13.3037V17.0554C44.1843 17.215 44.0564 17.3436 43.8976 17.3481C43.7415 17.3525 43.6109 17.2283 43.6065 17.0713C43.6065 17.066 43.6065 17.0607 43.6065 17.0554V13.3037C43.6065 12.0354 42.8921 10.776 41.4896 10.776C39.6902 10.776 38.914 12.3547 39.0199 13.9778C39.0199 14.0221 39.0375 14.2084 39.0375 14.235V17.0465C39.0375 17.2017 38.9246 17.333 38.7729 17.3569C38.615 17.3782 38.4703 17.2665 38.4492 17.1077C38.4474 17.0935 38.4465 17.0784 38.4465 17.0642V4.72725C38.4483 4.56583 38.5771 4.43634 38.7376 4.43457C38.8964 4.439 39.0243 4.56761 39.0287 4.72725V11.6984C39.5315 10.7938 40.4311 10.1818 41.4808 10.1818C43.2096 10.1818 44.1887 11.6984 44.1887 13.3037Z"
			fill="currentColor"
		/>
		<path
			d="M54.6941 13.8093C54.6914 13.9831 54.5494 14.1215 54.3765 14.1197H48.5286C48.6785 15.5742 49.7634 16.9046 51.2982 16.9046C52.3478 16.9046 53.124 16.5055 53.7062 15.6629C53.7609 15.5734 53.857 15.5166 53.962 15.5122C54.1181 15.5077 54.2486 15.6319 54.253 15.7889C54.253 15.7942 54.253 15.7995 54.253 15.8048C54.253 15.8581 54.2381 15.9104 54.2089 15.9556C53.618 16.9844 52.4449 17.49 51.2982 17.49C49.3312 17.49 47.9199 15.7162 47.9199 13.827C47.9199 11.9379 49.3224 10.1641 51.2982 10.1641C53.274 10.1641 54.6853 11.9202 54.6941 13.8093ZM54.059 13.5343C53.9355 12.0798 52.8241 10.7494 51.2894 10.7494C49.7546 10.7494 48.6697 12.0798 48.5197 13.5343H54.059Z"
			fill="currentColor"
		/>
		<path
			d="M61.8826 10.5811C61.894 10.7452 61.7723 10.888 61.6091 10.9004C59.8891 11.1576 59.1129 12.5678 59.1129 14.2086V17.0201C59.1129 17.1753 59 17.3066 58.8483 17.3305C58.6904 17.3518 58.5458 17.2401 58.5246 17.0813C58.5228 17.0671 58.5219 17.052 58.5219 17.0378V10.6254C58.5219 10.4702 58.6348 10.339 58.7866 10.315C58.9444 10.2937 59.0891 10.4055 59.1103 10.5642C59.112 10.5784 59.1129 10.5935 59.1129 10.6077V11.9115C59.598 11.0866 60.5595 10.3062 61.565 10.3062C61.715 10.3062 61.8826 10.4126 61.8826 10.5811Z"
			fill="currentColor"
		/>
		<path
			d="M71.4969 13.8093C71.4943 13.9831 71.3523 14.1215 71.1794 14.1197H65.3314C65.4813 15.5742 66.5663 16.9046 68.101 16.9046C69.1507 16.9046 69.9269 16.5055 70.509 15.6629C70.5637 15.5734 70.6598 15.5166 70.7648 15.5122C70.9209 15.5077 71.0515 15.6319 71.0559 15.7889C71.0559 15.7942 71.0559 15.7995 71.0559 15.8048C71.0559 15.8581 71.0409 15.9104 71.0118 15.9556C70.4208 16.9844 69.2477 17.49 68.101 17.49C66.1341 17.49 64.7228 15.7162 64.7228 13.827C64.7228 11.9379 66.1252 10.1641 68.101 10.1641C70.0768 10.1641 71.4881 11.9202 71.4969 13.8093ZM70.8707 13.5343C70.7472 12.0798 69.6358 10.7494 68.101 10.7494C66.5663 10.7494 65.4813 12.0798 65.3314 13.5343H70.8707Z"
			fill="currentColor"
		/>
		<path
			d="M80.9525 10.6518V17.0908C80.948 17.2504 80.8202 17.379 80.6614 17.3835C80.5053 17.3879 80.3747 17.2637 80.3703 17.1067C80.3703 17.1014 80.3703 17.0961 80.3703 17.0908V15.8668C79.8852 16.8336 79.0296 17.5342 77.9359 17.5342C76.1982 17.5342 75.2368 16.0176 75.2368 14.4123V10.6429C75.2385 10.4815 75.3673 10.352 75.5279 10.3502C75.6866 10.3547 75.8145 10.4833 75.8189 10.6429V14.4123C75.8189 15.6806 76.5334 16.94 77.9359 16.94C79.9028 16.94 80.3703 15.0863 80.3703 13.0376V10.634C80.373 10.47 80.507 10.3387 80.6702 10.3413C80.7037 10.3413 80.7364 10.3476 80.7672 10.3591C80.8801 10.4123 80.9525 10.5258 80.9525 10.6518Z"
			fill="currentColor"
		/>
		<path
			d="M95.7622 13.277V17.0464C95.7578 17.206 95.6299 17.3346 95.4711 17.339C95.315 17.3435 95.1845 17.2193 95.18 17.0623C95.18 17.057 95.18 17.0517 95.18 17.0464V13.277C95.18 12.0087 94.4656 10.767 93.0631 10.767C91.299 10.767 90.6287 12.665 90.6287 14.1373V17.0464C90.6243 17.206 90.4964 17.3346 90.3376 17.339C90.1815 17.3435 90.0509 17.2193 90.0465 17.0623C90.0465 17.057 90.0465 17.0517 90.0465 17.0464V13.277C90.0465 12.0087 89.3321 10.767 87.9296 10.767C86.1479 10.767 85.4158 12.1772 85.4775 14.0575C85.4775 14.1018 85.4952 14.1816 85.4775 14.2082V17.0375C85.4775 17.1927 85.3646 17.324 85.2129 17.3479C85.055 17.3692 84.9103 17.2574 84.8892 17.0987C84.8874 17.0845 84.8865 17.0694 84.8865 17.0552V10.6251C84.8865 10.4699 84.9994 10.3386 85.1512 10.3147C85.309 10.2934 85.4537 10.4051 85.4749 10.5639C85.4766 10.5781 85.4775 10.5932 85.4775 10.6074V11.6805C85.9803 10.7759 86.88 10.1816 87.9296 10.1816C89.1204 10.1816 90.0465 10.9444 90.4258 12.053C90.9109 10.9533 91.8547 10.1816 93.0631 10.1816C94.7831 10.1816 95.7622 11.6805 95.7622 13.277Z"
			fill="currentColor"
		/>
		<path
			opacity="0.6"
			d="M7.82198 8.87793L0.500977 12.2216L7.82198 16.5764L15.1518 12.2216L7.82198 8.87793Z"
			fill="currentColor"
		/>
		<path
			opacity="0.45"
			d="M0.500977 12.2217L7.82198 16.5764V0L0.500977 12.2217Z"
			fill="currentColor"
		/>
		<path
			opacity="0.8"
			d="M7.82211 0V16.5764L15.1519 12.2217L7.82211 0Z"
			fill="currentColor"
		/>
		<path
			opacity="0.45"
			d="M0.500977 13.623L7.82198 24V17.9689L0.500977 13.623Z"
			fill="currentColor"
		/>
		<path
			opacity="0.8"
			d="M7.82211 17.9689V24L15.1519 13.623L7.82211 17.9689Z"
			fill="currentColor"
		/>
	</svg>
)

export default EthereumLogo
