const SolanaLogo = ({ ...props }) => (
	<svg
		{...props}
		width="129"
		height="24"
		viewBox="0 0 129 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_873_95976)">
			<g clipPath="url(#clip1_873_95976)">
				<path
					d="M5.2505 18.2366C5.43448 18.0526 5.68745 17.9453 5.95575 17.9453H30.2869C30.7315 17.9453 30.9538 18.4819 30.6395 18.7962L25.8331 23.6026C25.6491 23.7866 25.3961 23.8939 25.1278 23.8939H0.796695C0.352081 23.8939 0.129774 23.3573 0.44407 23.043L5.2505 18.2366Z"
					fill="url(#paint0_linear_873_95976)"
				/>
				<path
					d="M5.2505 0.291299C5.44215 0.107321 5.69512 0 5.95575 0H30.2869C30.7315 0 30.9538 0.536603 30.6395 0.8509L25.8331 5.65733C25.6491 5.84131 25.3961 5.94863 25.1278 5.94863H0.796695C0.352081 5.94863 0.129774 5.41203 0.44407 5.09773L5.2505 0.291299Z"
					fill="url(#paint1_linear_873_95976)"
				/>
				<path
					d="M25.8331 9.20634C25.6491 9.02236 25.3961 8.91504 25.1278 8.91504H0.796695C0.352081 8.91504 0.129774 9.45164 0.44407 9.76594L5.2505 14.5724C5.43448 14.7563 5.68745 14.8637 5.95575 14.8637H30.2869C30.7315 14.8637 30.9538 14.3271 30.6395 14.0128L25.8331 9.20634Z"
					fill="url(#paint2_linear_873_95976)"
				/>
			</g>
			<path
				d="M61.7063 6.08457C62.9888 6.08457 64.2714 6.0834 65.5539 6.08457C66.8665 6.08575 67.8065 7.03075 67.8071 8.34904C67.8082 10.7725 67.8082 13.1959 67.8071 15.6193C67.8065 16.9488 66.86 17.9026 65.5351 17.9038C62.98 17.9062 60.4249 17.9062 57.8692 17.9038C56.5578 17.9026 55.6019 16.9618 55.5972 15.6464C55.5884 13.1935 55.5884 10.7407 55.5972 8.2878C55.6019 7.03723 56.5819 6.0834 57.8292 6.08398C59.1218 6.08398 60.4137 6.08398 61.7063 6.08398V6.08457ZM61.6957 15.6205C62.4883 15.6205 63.2814 15.6217 64.074 15.6205C64.9394 15.6187 65.5181 15.0535 65.521 14.1945C65.5257 12.6548 65.5257 11.1146 65.521 9.57488C65.5181 8.76354 64.9423 8.19654 64.1263 8.19477C62.5112 8.19183 60.8967 8.19124 59.2816 8.19477C58.4761 8.19654 57.8897 8.77178 57.8856 9.58077C57.8774 11.1204 57.878 12.6607 57.8856 14.2004C57.8897 15.0364 58.4849 15.6176 59.3186 15.6199C60.1111 15.6223 60.9043 15.6199 61.6969 15.6199L61.6957 15.6205Z"
				fill="currentColor"
			/>
			<path
				d="M128.5 12.8079C128.5 14.3281 128.5 15.8484 128.5 17.3686C128.5 17.7902 128.38 17.912 127.965 17.9132C127.564 17.9144 127.163 17.9132 126.761 17.9132C126.332 17.9132 126.213 17.7925 126.213 17.3492C126.211 16.1916 126.213 15.0341 126.212 13.8771C126.212 13.4402 126.097 13.3278 125.653 13.3278C123.5 13.3278 121.347 13.3278 119.193 13.3278C118.737 13.3278 118.627 13.4402 118.626 13.9007C118.626 15.0582 118.626 16.2152 118.626 17.3727C118.626 17.7902 118.501 17.9144 118.086 17.9156C117.685 17.9168 117.283 17.9168 116.882 17.9156C116.47 17.9144 116.344 17.7884 116.344 17.3686C116.343 15.4657 116.344 13.5627 116.344 11.6603C116.344 10.5817 116.342 9.50245 116.344 8.4238C116.347 7.01779 117.281 6.08515 118.684 6.08515C121.199 6.08456 123.715 6.08397 126.23 6.08515C127.556 6.08515 128.497 7.02956 128.5 8.36492C128.503 9.84571 128.501 11.3271 128.501 12.8079H128.5ZM122.447 11.6168C123.533 11.6168 124.62 11.618 125.707 11.6168C126.093 11.6168 126.21 11.4973 126.211 11.1063C126.213 10.6942 126.214 10.282 126.211 9.86985C126.209 9.59548 126.234 9.31463 126.183 9.0485C126.058 8.3873 125.514 7.99163 124.759 7.98869C123.368 7.98398 121.978 7.98633 120.588 7.98869C120.324 7.98869 120.057 7.98221 119.796 8.01283C119.105 8.09408 118.634 8.63164 118.628 9.33288C118.622 9.92167 118.625 10.5104 118.626 11.0992C118.628 11.4961 118.752 11.6174 119.156 11.618C120.253 11.6191 121.35 11.618 122.447 11.618V11.6168Z"
				fill="currentColor"
			/>
			<path
				d="M85.1854 12.7688C85.1854 11.3269 85.2177 9.88435 85.176 8.4436C85.1384 7.1365 86.1078 6.05961 87.5508 6.07787C90.0072 6.10907 92.4642 6.08552 94.9206 6.0867C96.2531 6.0867 97.1902 6.99048 97.1955 8.3229C97.2073 11.3534 97.2002 14.3844 97.1985 17.4155C97.1985 17.8041 97.0792 17.9142 96.6838 17.916C96.2531 17.9171 95.8225 17.9201 95.3918 17.9148C95.0816 17.9107 94.9547 17.7741 94.9547 17.4608C94.9547 16.2644 94.9559 15.0674 94.9547 13.871C94.9547 13.453 94.8343 13.3317 94.4124 13.3317C92.2692 13.3305 90.1253 13.3305 87.982 13.3317C87.5696 13.3317 87.4374 13.4653 87.4368 13.8787C87.435 15.0456 87.4368 16.2132 87.4356 17.3802C87.4356 17.8147 87.3328 17.9171 86.9033 17.9183C86.5214 17.9195 86.1401 17.9183 85.7582 17.9183C85.2718 17.9183 85.186 17.8306 85.186 17.3307C85.186 15.8105 85.186 14.2902 85.186 12.77L85.1854 12.7688ZM91.1752 11.6166C92.241 11.6166 93.3067 11.6166 94.3725 11.6166C94.8407 11.6166 94.95 11.5041 94.95 11.0242C94.95 10.5244 94.95 10.0245 94.95 9.52519C94.9494 8.51013 94.4301 7.98847 93.416 7.98729C92.2327 7.98611 91.0495 7.98729 89.8662 7.98729C89.5143 7.98729 89.1618 7.97905 88.8104 7.98965C87.9632 8.01496 87.4327 8.5643 87.4298 9.41156C87.428 9.96031 87.4292 10.5091 87.4298 11.0578C87.4298 11.5006 87.5437 11.6171 87.9773 11.6177C89.0431 11.6183 90.1088 11.6177 91.1746 11.6177L91.1752 11.6166Z"
				fill="currentColor"
			/>
			<path
				d="M44.9085 17.9138C43.6553 17.9138 42.4027 17.9114 41.1495 17.9162C40.9204 17.9167 40.7077 17.8967 40.6219 17.6459C40.5408 17.4092 40.6889 17.2644 40.854 17.1236C41.3616 16.6921 41.8551 16.244 42.3727 15.826C42.5114 15.7141 42.717 15.6305 42.8927 15.6293C44.7721 15.6152 46.6516 15.6228 48.5311 15.6193C49.0593 15.6181 49.4594 15.3773 49.6738 14.8922C49.8806 14.4247 49.8207 13.9695 49.4847 13.5733C49.2291 13.2718 48.893 13.1441 48.497 13.1447C46.9013 13.1488 45.3056 13.1482 43.7099 13.1458C41.6765 13.1435 40.1219 11.5761 40.1348 9.546C40.1466 7.70488 41.6906 6.10751 43.5272 6.09102C46.0136 6.06924 48.5 6.08572 50.9864 6.08337C51.1926 6.08337 51.39 6.13754 51.4199 6.34538C51.4411 6.49139 51.38 6.701 51.2772 6.80345C50.7925 7.28802 50.2896 7.75551 49.7731 8.20652C49.655 8.30956 49.4594 8.3702 49.299 8.37138C47.4195 8.38257 45.54 8.37668 43.6606 8.38021C43.1318 8.38139 42.7323 8.62279 42.5172 9.10677C42.3151 9.56249 42.3692 10.0111 42.6888 10.4039C42.9455 10.7195 43.2881 10.8555 43.6958 10.8543C45.3209 10.8502 46.946 10.8519 48.5705 10.8531C50.3871 10.8543 51.9229 12.2986 52.0539 14.1273C52.1849 15.9579 50.8753 17.6241 49.0734 17.8561C48.4371 17.9379 47.785 17.9061 47.1404 17.9132C46.3966 17.9215 45.6523 17.915 44.9085 17.915V17.9138Z"
				fill="currentColor"
			/>
			<path
				d="M100.752 11.9912C100.752 10.2154 100.752 8.43964 100.752 6.66446C100.752 6.17459 100.846 6.08274 101.334 6.08274C101.784 6.08274 102.235 6.09275 102.685 6.07862C102.923 6.07097 103.087 6.15163 103.239 6.33474C104.624 7.99923 106.017 9.65725 107.406 11.3176C108.167 12.2267 108.925 13.1381 109.689 14.0449C109.836 14.2191 109.961 14.46 110.256 14.3634C110.565 14.2615 110.522 13.9936 110.522 13.7493C110.522 11.3947 110.522 9.0402 110.522 6.68625C110.522 6.18931 110.628 6.08333 111.121 6.08274C111.503 6.08274 111.885 6.08157 112.266 6.08274C112.664 6.08392 112.793 6.20757 112.794 6.6097C112.798 7.58061 112.795 8.5521 112.795 9.52359C112.795 12.1137 112.795 14.7031 112.795 17.2932C112.795 17.8131 112.693 17.9161 112.184 17.9161C111.714 17.9161 111.244 17.9273 110.774 17.9085C110.649 17.9032 110.488 17.8384 110.408 17.7454C108.207 15.1918 106.015 12.6312 103.822 10.0723C103.771 10.0129 103.721 9.95105 103.663 9.89924C103.428 9.68904 103.099 9.79326 103.031 10.1047C103.009 10.2084 103.015 10.319 103.015 10.4268C103.014 12.7124 103.015 14.9981 103.014 17.2844C103.014 17.8578 102.952 17.9185 102.369 17.9185C101.997 17.9185 101.625 17.9197 101.253 17.9185C100.861 17.9167 100.753 17.8095 100.752 17.4074C100.751 15.6022 100.752 13.7976 100.752 11.9924L100.752 11.9912Z"
				fill="currentColor"
			/>
			<path
				d="M71.4686 11.9836C71.4686 10.2179 71.4686 8.45268 71.4686 6.68692C71.4686 6.18351 71.5708 6.08048 72.0661 6.07989C72.448 6.07989 72.8293 6.07812 73.2112 6.07989C73.5831 6.08165 73.7006 6.1947 73.7017 6.57388C73.7053 7.88804 73.7035 9.2028 73.7035 10.517C73.7035 12.1155 73.7035 13.7146 73.7035 15.3132C73.7035 15.8602 73.7934 15.952 74.331 15.952C76.7486 15.952 79.1663 15.952 81.5833 15.9532C81.7002 15.9532 81.8224 15.9473 81.9329 15.9785C82.1832 16.0492 82.2807 16.3648 82.0998 16.5732C81.7331 16.9936 81.3507 17.4016 80.9582 17.7984C80.8812 17.8762 80.7285 17.9103 80.611 17.9103C77.7239 17.9162 74.8362 17.9156 71.9492 17.9139C71.5919 17.9139 71.4697 17.7737 71.4697 17.3675C71.468 15.5723 71.4692 13.7776 71.4692 11.9824L71.4686 11.9836Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_873_95976"
				x1="27.9626"
				y1="-2.87107"
				x2="11.1232"
				y2="29.3819"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#00FFA3" />
				<stop offset="1" stopColor="#DC1FFF" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_873_95976"
				x1="20.6004"
				y1="-6.71521"
				x2="3.76104"
				y2="25.5385"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#00FFA3" />
				<stop offset="1" stopColor="#DC1FFF" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_873_95976"
				x1="24.2578"
				y1="-4.80591"
				x2="7.41915"
				y2="27.4478"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#00FFA3" />
				<stop offset="1" stopColor="#DC1FFF" />
			</linearGradient>
			<clipPath id="clip0_873_95976">
				<rect
					width="128.204"
					height="24"
					fill="currentColor"
					transform="translate(0.296875)"
				/>
			</clipPath>
			<clipPath id="clip1_873_95976">
				<rect
					width="30.4898"
					height="23.8942"
					fill="currentColor"
					transform="translate(0.296875)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default SolanaLogo
