import { Link } from '@remix-run/react'
import Button from '../buttons/button'
import { CollectionCard, NftCard, ContentTile } from '../content'
import { ArrowRightIcon, CaretLeft, CaretRight } from '../icons'
import {
	TypographyCaps,
	TypographyHeading,
	TypographyLabel,
	TypographyParagraph,
} from '../typography'
import { Carousel } from 'react-responsive-carousel'
import Image from '../content/Image'
import { useLayoutEffect, useRef, useState } from 'react'
import { artist, collection, nft } from '#types/loreTypes.tsx'
import { ClientOnly } from 'remix-utils/client-only'
import { getNFTProfileURL } from '#app/utils/misc.tsx'
import InternalLink from '../common/internalLink.tsx'

export default function IndexContent({
	artists,
	collections,
	nfts,
	atomicSignUrl,
}: {
	artists: artist[]
	collections: collection[]
	nfts: nft[]
	atomicSignUrl: string
}) {
	return (
		<div className="bg-secondaryLight dark:bg-secondaryDark w-full">
			{/*<ArtistCarousel
                sectionName="Artists"
                linkPreText={"Check out all artist profiles"}
                linkText={"Browse Artists"}
                link="/artistsList"
                sectionDescription="Meet some of the creators behind the most influential collections in the space."
                artists={artists}
            />
            <CollectionCarousel
                sectionName="Collections"
                linkPreText={"Check out all collections"}
                linkText={"View Collections"}
                link="/collectionsList"
                sectionDescription="Discover the history behind the most famous collections."
                collections={collections}
            />*/}
			<NFTCarousel
				sectionName="Featured in Lore"
				sectionTitle="Looking for inspiration?"
				sectionDescription="Explore the captivating stories behind renowned artworks."
				nfts={nfts}
				atomicSignUrl={atomicSignUrl}
			/>
			{/*<MarketplaceList
                sectionName="Marketplaces"
                linkText="View All Marketplaces"
                link="/marketplaces"
                sectionDescription="With over 180 platforms, discover the place that matches your style."
                entries={[
                    {src:  monaImage, alt: "placeholder", title: "Opensea", blockchain: "ETH", description: "Short intro text", slug: "brian-brinkman"},
                    {src:  monaImage, alt: "placeholder", title: "Solanaart", blockchain: "Sol", description: "Short intro text", slug: "brian-brinkman"},
                ]}
            />*/}
		</div>
	)
}

const ArtistCarousel = ({
	sectionName,
	sectionDescription,
	linkPreText,
	linkText,
	link,
	artists,
}: {
	sectionName: string
	sectionDescription: string
	linkPreText: string
	linkText: string
	link: string
	artists: artist[]
}) => {
	return (
		<div className="pt-32">
			<TypographyCaps
				size="medium"
				additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
			>
				{sectionName}
			</TypographyCaps>
			<TypographyHeading
				size="xLarge"
				isBold={true}
				additionalClasses="pb-12 pt-3 text-textPrimaryLight dark:text-textPrimaryDark lg:max-w-[65%]"
			>
				{sectionDescription}
			</TypographyHeading>
			<div className="-mx-4 flex flex-wrap">
				{artists.slice(0, 4).map(artist => (
					<>
						<div key={artist.id} className="mb-8 w-full px-4 sm:w-1/2 lg:w-1/4">
							<InternalLink
								to={`/artistProfile/${artist.id}`}
								additionalClasses="w-full"
							>
								<ArtistCard
									name={artist.name}
									description={artist.topDescription}
									image={artist.src}
								></ArtistCard>
							</InternalLink>
						</div>
					</>
				))}
			</div>
			<InternalLink to={link}>
				<div className="group flex h-[26px] w-full items-center justify-center gap-x-1 pt-12">
					<TypographyParagraph
						size="large"
						additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
					>
						{linkPreText}
					</TypographyParagraph>
					<ArrowRightIcon className="ml-2 h-4 w-4 text-textPrimaryLight dark:text-textPrimaryDark" />
					<TypographyLabel
						size="large"
						isBold={true}
						additionalClasses="group-hover:underline text-textPrimaryLight dark:text-textPrimaryDark"
					>
						{linkText}
					</TypographyLabel>
				</div>
			</InternalLink>
		</div>
	)
}

const CollectionCarousel = ({
	sectionName,
	sectionDescription,
	linkPreText,
	linkText,
	link,
	collections,
}: {
	sectionName: string
	sectionDescription: string
	linkPreText: string
	linkText: string
	link: string
	collections: collection[]
}) => {
	return (
		<div className="pt-32">
			<TypographyCaps
				size="medium"
				additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
			>
				{sectionName}
			</TypographyCaps>
			<TypographyHeading
				size="xLarge"
				isBold={true}
				additionalClasses="pb-12 pt-3 text-textPrimaryLight dark:text-textPrimaryDark lg:max-w-[65%]"
			>
				{sectionDescription}
			</TypographyHeading>
			<div className="-mx-4 flex flex-wrap">
				{collections.slice(0, 4).map(collection => (
					<>
						<div
							key={collection.id}
							className="mb-8 w-full px-4  sm:w-1/2 lg:w-1/4"
						>
							<InternalLink
								to={`/collectionProfile/${collection.id}`}
								additionalClasses="w-1/2 lg:w-1/4"
							>
								<CollectionCard
									name={collection.collectionName}
									artist={collection.artistName}
									blockchain={
										collection.collectionNetwork
											? collection.collectionNetwork == 'eth-mainnet'
												? 'ETH'
												: 'SOL'
											: 'Unknown'
									}
									count={
										collection.refCollectionCount
											? collection.refCollectionCount
											: 0
									}
									image={collection.collectionHeaderImage}
									cardAdditionalProps="h-full w-auto"
									containerAdditionalProps="relative w-full pt-[100%] bg-grey100 dark:bg-grey950"
									imageAdditionalProps="absolute top-0 left-0 w-full h-full object-contain"
								/>
							</InternalLink>
						</div>
					</>
				))}
			</div>
			<InternalLink to={link}>
				<div className="group flex h-[26px] w-full items-center justify-center gap-x-1 pt-12">
					<TypographyParagraph
						size="large"
						additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
					>
						{linkPreText}
					</TypographyParagraph>
					<ArrowRightIcon className="ml-2 h-4 w-4 text-textPrimaryLight dark:text-textPrimaryDark" />
					<TypographyLabel
						size="large"
						isBold={true}
						additionalClasses="group-hover:underline text-textPrimaryLight dark:text-textPrimaryDark"
					>
						{linkText}
					</TypographyLabel>
				</div>
			</InternalLink>
		</div>
	)
}

const calculateCenterSlidePercentage = (num: number | undefined) => {
	if (!num) {
		return 25
	}
	if (num <= 769 && num > 640) {
		return 50
	} else if (num <= 640) {
		return 100
	} else {
		return 25
	}
}

const ClientOnlyCarousel = ({
	nfts,
	carouselRef,
	selectedItem,
}: {
	nfts: nft[]
	carouselRef: any
	selectedItem: number
}) => {
	const [centerSlidePercentage, setCenterSlidePercentage] = useState(
		calculateCenterSlidePercentage(window.innerWidth),
	)

	useLayoutEffect(() => {
		function updateSize() {
			setCenterSlidePercentage(
				calculateCenterSlidePercentage(window.innerWidth),
			)
		}
		window.addEventListener('resize', updateSize)
		updateSize()
		return () => window.removeEventListener('resize', updateSize)
	}, [])

	return (
		<Carousel
			ref={carouselRef}
			selectedItem={selectedItem}
			centerMode
			centerSlidePercentage={centerSlidePercentage}
			showIndicators={false}
			showArrows={false}
			showStatus={false}
			showThumbs={false}
		>
			{nfts.map((nft, index) => {
				const nftProfileURL = getNFTProfileURL(nft)
				return (
					<div key={index} className="mb-4 w-full sm:px-4 lg:px-6">
						<a href={nftProfileURL} className="w-full">
							{/* <Link to={nftProfileURL} className="w-full"> */}
							<NftCard
								name={nft.name}
								image={nft.previewImage ? nft.previewImage : ''}
								currency={nft.currency ? nft.currency : ''}
								price={nft.price ? nft.price : ''}
								collectionId={
									nft.collection ? nft.collection.toString() : undefined
								}
								collectionName={nft.collectionName ? nft.collectionName : ''}
								chain={nft.chain ? nft.chain : ''}
								contract={nft.contract.address ? nft.contract.address : ''}
								tokenId={nft.tokenId}
								cardAdditionalProps="h-full w-auto"
								containerAdditionalProps="relative w-full pt-[100%] bg-grey100 dark:bg-grey950"
								imageAdditionalProps="absolute top-0 left-0 w-full h-full object-contain"
							/>
						</a>
						{/* </Link> */}
					</div>
				)
			})}
		</Carousel>
	)
}

const NFTCarousel = ({
	sectionName,
	sectionTitle,
	sectionDescription,
	nfts,
	atomicSignUrl,
}: {
	sectionName: string
	sectionTitle: string
	sectionDescription: string
	nfts: nft[]
	atomicSignUrl: string
}) => {
	const carouselRef = useRef(null)
	const [selectedItem, setSelectedItem] = useState(0)

	const goToPrevSlide = () => {
		selectedItem == 0 ? setSelectedItem(0) : setSelectedItem(selectedItem - 1)
	}

	const goToNextSlide = () => {
		selectedItem == nfts.length - 1
			? setSelectedItem(nfts.length - 1)
			: setSelectedItem(selectedItem + 1)
	}

	return (
		<div id="indexContent" className="pt-16">
			<TypographyCaps
				size="small"
				additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
			>
				{sectionName}
			</TypographyCaps>
			<TypographyHeading
				size="large"
				isBold={true}
				additionalClasses="pt-3 text-textPrimaryLight dark:text-textPrimaryDark"
			>
				{sectionTitle}
			</TypographyHeading>
			<div className="flex flex-row justify-between">
				<TypographyHeading
					size="large"
					isBold={true}
					additionalClasses="pb-12 text-textPrimaryLight dark:text-textPrimaryDark"
				>
					{sectionDescription}
				</TypographyHeading>
				<div className="mb-4 hidden sm:block">
					<div className="flex flex-row items-center justify-center gap-x-4">
						<Button
							variant="secondary"
							additionalClasses="rounded-full pl-0 pr-0 px-2"
							onClick={goToPrevSlide}
						>
							<CaretLeft />
						</Button>
						<Button
							variant="secondary"
							additionalClasses="rounded-full pl-0 pr-0 px-2"
							onClick={goToNextSlide}
						>
							<CaretRight />
						</Button>
					</div>
				</div>
			</div>
			<div className="test-id test-home-nft-carousel flex-wrap md:flex lg:block">
				<ClientOnly fallback={<div></div>}>
					{() => (
						<ClientOnlyCarousel
							nfts={nfts}
							carouselRef={carouselRef}
							selectedItem={selectedItem}
						/>
					)}
				</ClientOnly>
			</div>
		</div>
	)
}

const MarketplaceList = ({
	sectionName,
	sectionDescription,
	linkText,
	link,
	entries,
}: {
	sectionName: string
	sectionDescription: string
	linkPreText: string
	linkText: string
	link: string
	entries: any[]
}) => {
	return (
		<div className="pt-32">
			<TypographyCaps
				size="medium"
				additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
			>
				{sectionName}
			</TypographyCaps>
			<TypographyHeading
				size="xLarge"
				isBold={true}
				additionalClasses="pb-12 pt-3 text-textPrimaryLight dark:text-textPrimaryDark lg:max-w-[65%]"
			>
				{sectionDescription}
			</TypographyHeading>
			<div className="flex flex-col justify-between sm:flex-row">
				<div className="flex w-[50%] flex-col">
					<TypographyLabel size="medium" isBold={true} additionalClasses="pb-6">
						ETH Marketplaces
					</TypographyLabel>
					{entries
						.filter(marketplace => marketplace.blockchain == 'ETH')
						.map((entry, index) => {
							return (
								<div
									key={index}
									className="bg-secondaryLight dark:bg-secondaryDark w-full"
								>
									<div className="w-[80%] border-b border-dotted border-textSecondaryLight dark:border-textSecondaryDark">
										<ContentTile
											variant="user"
											showCaret={false}
											title={entry.title}
											description={entry.description}
											image={entry.src}
											isVerified={false}
											showBorder={false}
										/>
									</div>
								</div>
							)
						})}
				</div>
				<div className="flex w-[50%] flex-col">
					<TypographyLabel size="medium" isBold={true} additionalClasses="pb-6">
						Other Blockchain Marketplaces
					</TypographyLabel>
					{entries
						.filter(marketplace => marketplace.blockchain != 'ETH')
						.map((entry, index) => {
							return (
								<div
									key={index}
									className="bg-secondaryLight dark:bg-secondaryDark w-full"
								>
									<div className="w-[80%] border-b border-dotted border-textSecondaryLight dark:border-textSecondaryDark">
										<ContentTile
											variant="user"
											showCaret={false}
											title={entry.title}
											description={entry.description}
											image={entry.src}
											isVerified={false}
											showBorder={false}
										/>
									</div>
								</div>
							)
						})}
				</div>
			</div>
			<InternalLink to={link}>
				<div className="group flex h-[26px] w-full gap-x-1 pt-12">
					<ArrowRightIcon className="ml-2 h-4 w-4 text-textPrimaryLight dark:text-textPrimaryDark" />
					<TypographyLabel
						size="large"
						isBold={true}
						additionalClasses="group-hover:underline text-textPrimaryLight dark:text-textPrimaryDark"
					>
						{linkText}
					</TypographyLabel>
				</div>
			</InternalLink>
		</div>
	)
}

const ArtistCard = ({
	name,
	description,
	image,
	...props
}: {
	name: string
	description: string
	image: string
}) => {
	return (
		<div
			className={`group flex h-full w-auto cursor-pointer flex-col border border-textInactiveLight hover:border-[#2C2C2C] hover:drop-shadow-menu dark:border-textInactiveDark dark:hover:border-white hover:dark:drop-shadow-none`}
			{...props}
		>
			<Image
				className="h-full w-full object-cover"
				containerClassName="relative aspect-w-1 aspect-h-1 mb-4"
				imageUrl={image}
				imageAlt={name}
			></Image>
			<div className="mx-2 mb-2 flex flex-col justify-center">
				<div className="flex dark:group-hover:underline">
					<TypographyParagraph
						size="medium"
						additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
					>
						{name}
					</TypographyParagraph>
				</div>
				<TypographyParagraph
					size="xSmall"
					additionalClasses="line-clamp-2 text-textSecondaryLight group-hover:text-textPrimaryLight dark:text-textSecondaryDark dark:group-hover:text-textPrimaryDark"
				>
					{description}
				</TypographyParagraph>
			</div>
		</div>
	)
}
