const BaseLogo = ({ ...props }) => (
	<svg
		{...props}
		width="95"
		height="24"
		viewBox="0 0 95 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_873_96004)">
			<path
				d="M12.0881 23.9996C11.7779 23.9643 11.4669 23.9346 11.1579 23.8921C8.3297 23.5048 5.92977 22.2738 3.9784 20.1984C2.11162 18.2132 1.06187 15.846 0.78439 13.137C0.781382 13.1065 0.784014 13.0753 0.784014 13.0219H16.627V11.0025H0.762207C0.821237 10.5893 0.853572 10.1968 0.935161 9.81475C1.89318 5.34652 4.492 2.23749 8.7963 0.691805C15.5024 -1.71639 22.8932 2.39766 24.4464 9.32258C25.8221 15.4583 22.3318 21.5452 16.3457 23.4473C15.4415 23.7345 14.5154 23.9097 13.5676 23.9688C13.5123 23.9722 13.4582 23.9891 13.4036 23.9996H12.0881Z"
				fill="white"
			/>
			<mask
				id="mask0_873_96004"
				mask-type="luminance"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="25"
				height="25"
			>
				<path
					d="M12.763 24.0016C19.3909 24.0016 24.7638 18.6286 24.7638 12.0008C24.7638 5.37294 19.3909 0 12.763 0C6.13514 0 0.762207 5.37294 0.762207 12.0008C0.762207 18.6286 6.13514 24.0016 12.763 24.0016Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_873_96004)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0007L-4.21085 11.896L-4.19548 11.272L12.7631 12.0007Z"
					fill="#FEFEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0008L-4.19853 11.3758L-4.17261 10.8555L12.7629 12.0008Z"
					fill="#FDFEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-4.17827 10.9598L-4.13987 10.4404L12.763 12.0005Z"
					fill="#FCFDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0007L-4.14768 10.5443L-4.0968 10.0259L12.7629 12.0007Z"
					fill="#FBFCFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.001L-4.10636 10.1298L-4.043 9.61328L12.7629 12.001Z"
					fill="#FAFCFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0007L-4.05551 9.71673L-3.97967 9.20117L12.7629 12.0007Z"
					fill="#F9FBFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-3.99393 9.30369L-3.90561 8.79102L12.763 12.0005Z"
					fill="#F8FAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0008L-3.92282 8.89406L-3.82201 8.3833L12.7631 12.0008Z"
					fill="#F7FAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0007L-3.84218 8.48588L-3.72793 7.97705L12.7631 12.0007Z"
					fill="#F6F9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0006L-3.75081 8.07872L-3.62408 7.57373L12.7632 12.0006Z"
					fill="#F5F8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L-3.6491 7.67448L-3.51085 7.17236L12.7632 12.0005Z"
					fill="#F4F8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L-3.53906 7.27318L-3.38737 6.7749L12.7628 12.0005Z"
					fill="#F3F7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L-3.41789 6.8748L-3.25372 6.38037L12.763 12.0006Z"
					fill="#F2F6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0007L-3.28719 6.47936L-3.11149 5.98877L12.7632 12.0007Z"
					fill="#F1F6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0009L-3.14616 6.08687L-2.95894 5.60107L12.7631 12.0009Z"
					fill="#F0F5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0006L-2.99638 5.69779L-2.79765 5.2168L12.7631 12.0006Z"
					fill="#EFF4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L-2.83708 5.31312L-2.62587 4.83789L12.763 12.0009Z"
					fill="#EEF3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0008L-2.66824 4.93283L-2.4455 4.4624L12.7629 12.0008Z"
					fill="#EDF3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0008L-2.49066 4.55645L-2.25641 4.09082L12.7628 12.0008Z"
					fill="#ECF2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0008L-2.30315 4.18401L-2.05737 3.7251L12.7631 12.0008Z"
					fill="#EBF1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-2.1065 3.8169L-1.85016 3.36279L12.763 12.0005Z"
					fill="#EAF1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0007L-1.90111 3.45424L-1.63325 3.00781L12.7629 12.0007Z"
					fill="#E9F0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-1.68697 3.09692L-1.40856 2.65625L12.7629 12.0005Z"
					fill="#E8EFFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.001L-1.46411 2.745L-1.17417 2.31201L12.7631 12.001Z"
					fill="#E7EFFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-1.23289 2.39796L-0.933355 1.97266L12.7629 12.0005Z"
					fill="#E6EEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0006L-0.992943 2.05824L-0.682842 1.63965L12.7628 12.0006Z"
					fill="#E5EDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L-0.745049 1.72341L-0.424387 1.3125L12.763 12.0009Z"
					fill="#E4EDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0008L-0.488808 1.39494L-0.158546 0.992676L12.7629 12.0008Z"
					fill="#E3ECFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L-0.223824 1.0733L0.116039 0.678711L12.763 12.0007Z"
					fill="#E2EBFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L0.0479126 0.757528L0.397376 0.371582L12.763 12.0008Z"
					fill="#E1EBFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0006L0.327194 0.449082L0.686258 0.0717773L12.7629 12.0006Z"
					fill="#E0EAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0004L0.614418 0.147473L0.983082 -0.221191L12.7631 12.0004Z"
					fill="#DFE9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0009L0.909988 -0.147756L1.28633 -0.505859L12.7629 12.0009Z"
					fill="#DEE9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L1.21152 -0.435205L1.59746 -0.784668L12.763 12.0005Z"
					fill="#DDE8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0007L1.51981 -0.714337L1.91439 -1.0542L12.7631 12.0007Z"
					fill="#DCE7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0006L1.83563 -0.986145L2.2379 -1.31641L12.7631 12.0006Z"
					fill="#DBE7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0007L2.15703 -1.2511L2.56794 -1.5708L12.7628 12.0007Z"
					fill="#DAE6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0008L2.48557 -1.50728L2.90416 -1.81738L12.7631 12.0008Z"
					fill="#D9E5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0006L2.82047 -1.75515L3.24578 -2.05469L12.7629 12.0006Z"
					fill="#D8E5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0006L3.16054 -1.9952L3.59353 -2.28418L12.7631 12.0006Z"
					fill="#D7E4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0007L3.50696 -2.22646L3.94763 -2.50488L12.7629 12.0007Z"
					fill="#D6E3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.001L3.85934 -2.44894L4.30577 -2.7168L12.763 12.001Z"
					fill="#D5E3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L4.21648 -2.6631L4.66963 -2.91943L12.763 12.0009Z"
					fill="#D4E2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L4.57959 -2.86897L5.0385 -3.11475L12.7632 12.0005Z"
					fill="#D3E1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0007L4.94627 -3.06553L5.4119 -3.29883L12.7631 12.0007Z"
					fill="#D2E0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0007L5.31852 -3.25283L5.78895 -3.47461L12.7629 12.0007Z"
					fill="#D1E0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0007L5.69513 -3.43039L6.17132 -3.6416L12.7631 12.0007Z"
					fill="#D0DFFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0009L6.07532 -3.59913L6.55727 -3.79883L12.7631 12.0009Z"
					fill="#CFDEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0009L6.46027 -3.75859L6.94703 -3.9458L12.7631 12.0009Z"
					fill="#CEDEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0004L6.84881 -3.90878L7.3394 -4.08447L12.7628 12.0004Z"
					fill="#CDDDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L7.24171 -4.04966L7.73615 -4.21191L12.763 12.0007Z"
					fill="#CCDCFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0006L7.63739 -4.18032L8.13566 -4.33105L12.7632 12.0006Z"
					fill="#CBDCFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0007L8.03546 -4.3012L8.53758 -4.43945L12.7628 12.0007Z"
					fill="#CADBFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0009L8.4371 -4.41136L8.9421 -4.53809L12.7631 12.0009Z"
					fill="#C9DAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L8.84113 -4.51318L9.349 -4.62646L12.763 12.0009Z"
					fill="#C8DAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L9.24833 -4.60476L9.75812 -4.70557L12.7631 12.0005Z"
					fill="#C7D9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0008L9.65633 -4.68513L10.169 -4.77441L12.7631 12.0008Z"
					fill="#C6D8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0007L10.0663 -4.7562L10.5819 -4.83301L12.7631 12.0007Z"
					fill="#C5D8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0009L10.4791 -4.8175L10.9956 -4.88086L12.7631 12.0009Z"
					fill="#C4D7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L10.8918 -4.86855L11.4103 -4.91943L12.763 12.0007Z"
					fill="#C3D6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L11.3066 -4.90984L11.826 -4.94824L12.763 12.0007Z"
					fill="#C2D6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0004L11.7221 -4.94086L12.2425 -4.96582L12.7629 12.0004Z"
					fill="#C1D5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0008L12.1381 -4.96066L12.6584 -4.97314L12.7631 12.0008Z"
					fill="#C0D4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0008L12.5548 -4.97119H13.0752L12.7632 12.0008Z"
					fill="#BFD4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L12.9713 -4.97119L13.4917 -4.95775L12.763 12.0008Z"
					fill="#BED3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L13.388 -4.96094L13.9074 -4.93502L12.763 12.0005Z"
					fill="#BDD2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L13.8037 -4.94043L14.3231 -4.90203L12.763 12.0008Z"
					fill="#BCD2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L14.2194 -4.90967L14.7379 -4.85878L12.763 12.0009Z"
					fill="#BBD1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L14.6332 -4.86865L15.1507 -4.80529L12.763 12.0006Z"
					fill="#BAD0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.001L15.047 -4.81738L15.5625 -4.74154L12.763 12.001Z"
					fill="#B9D0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L15.4589 -4.75635L15.9725 -4.66802L12.763 12.0006Z"
					fill="#B8CFFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L15.8698 -4.68506L16.3805 -4.58425L12.763 12.0008Z"
					fill="#B7CEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L16.2778 -4.60449L16.7866 -4.49024L12.763 12.0008Z"
					fill="#B6CDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L16.6849 -4.51318L17.1899 -4.38646L12.763 12.0009Z"
					fill="#B5CDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L17.089 -4.41162L17.5902 -4.27337L12.763 12.0007Z"
					fill="#B4CCFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L17.4904 -4.30127L17.9886 -4.14958L12.763 12.0006Z"
					fill="#B3CBFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L17.8888 -4.18018L18.3832 -4.016L12.763 12.0007Z"
					fill="#B2CBFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L18.2843 -4.0498L18.7749 -3.87411L12.763 12.0005Z"
					fill="#B1CAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L18.677 -3.90869L19.1628 -3.72148L12.763 12.0005Z"
					fill="#B0C9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L19.0649 -3.75879L19.5468 -3.56006L12.763 12.0006Z"
					fill="#AFC9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L19.4498 -3.59961L19.926 -3.3884L12.763 12.0005Z"
					fill="#AEC8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L19.831 -3.43018L20.3014 -3.20744L12.763 12.0009Z"
					fill="#ADC7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L20.2073 -3.25293L20.673 -3.01867L12.763 12.0006Z"
					fill="#ACC7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L20.5798 -3.06543L21.0387 -2.81965L12.763 12.0008Z"
					fill="#ABC6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L20.9466 -2.86865L21.4007 -2.61232L12.763 12.0008Z"
					fill="#AAC5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L21.3095 -2.66309L21.7559 -2.39523L12.763 12.0009Z"
					fill="#A9C5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L21.6666 -2.44922L22.1063 -2.1708L12.763 12.0007Z"
					fill="#A8C4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L22.019 -2.22656L22.452 -1.93662L12.763 12.0006Z"
					fill="#A7C3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L22.3656 -1.99512L22.7909 -1.69558L12.763 12.0007Z"
					fill="#A6C3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L22.7054 -1.75488L23.124 -1.44478L12.763 12.0009Z"
					fill="#A5C2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L23.0405 -1.50732L23.4504 -1.18666L12.763 12.0008Z"
					fill="#A4C1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L23.3688 -1.25098L23.7711 -0.920715L12.763 12.0008Z"
					fill="#A3C1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0004L23.6904 -0.986328L24.085 -0.646465L12.763 12.0004Z"
					fill="#A2C0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L24.0063 -0.714355L24.3923 -0.364892L12.763 12.0007Z"
					fill="#A1BFFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L24.3145 -0.435059L24.6918 -0.0759949L12.763 12.0006Z"
					fill="#A0BFFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L24.6159 -0.147949L24.9846 0.220715L12.763 12.0007Z"
					fill="#9FBEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0004L24.9107 0.147461L25.2697 0.523805L12.763 12.0004Z"
					fill="#9EBDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L25.1987 0.449219L25.5482 0.835164L12.763 12.0007Z"
					fill="#9DBDFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L25.4781 0.757324L25.8179 1.15191L12.763 12.0006Z"
					fill="#9CBCFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L25.7498 1.07324L26.08 1.47551L12.763 12.0007Z"
					fill="#9BBBFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L26.0148 1.39502L26.3345 1.80593L12.763 12.0008Z"
					fill="#9ABAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L26.2711 1.72314L26.5812 2.14173L12.763 12.0006Z"
					fill="#99BAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.001L26.5188 2.05859L26.8183 2.4839L12.763 12.001Z"
					fill="#98B9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L26.7588 2.39795L27.0478 2.83094L12.763 12.0005Z"
					fill="#97B8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L26.9902 2.74463L27.2686 3.1853L12.763 12.0006Z"
					fill="#96B8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L27.2129 3.09717L27.4808 3.5436L12.763 12.0008Z"
					fill="#95B7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L27.427 3.4541L27.6833 3.90725L12.763 12.0006Z"
					fill="#94B6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L27.6325 3.81689L27.8773 4.27581L12.763 12.0005Z"
					fill="#93B6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0004L27.8283 4.18359L28.0626 4.64922L12.763 12.0004Z"
					fill="#92B5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.001L28.0165 4.55664L28.2383 5.02707L12.763 12.001Z"
					fill="#91B4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L28.1941 4.93262L28.4053 5.40881L12.763 12.0006Z"
					fill="#90B4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L28.3631 5.31299L28.5618 5.79494L12.763 12.0008Z"
					fill="#8FB3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L28.5224 5.69775L28.7097 6.18451L12.763 12.0006Z"
					fill="#8EB2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0009L28.6722 6.08691L28.8479 6.57751L12.763 12.0009Z"
					fill="#8DB2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L28.8124 6.47949L28.9756 6.97392L12.763 12.0008Z"
					fill="#8CB1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L28.9429 6.875L29.0946 7.37327L12.763 12.0008Z"
					fill="#8BB0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L29.0639 7.27344L29.2031 7.77555L12.763 12.0008Z"
					fill="#8AB0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L29.1753 7.6748L29.302 8.1798L12.763 12.0008Z"
					fill="#89AFFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.2771 8.07861L29.3903 8.58649L12.763 12.0005Z"
					fill="#88AEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L29.3673 8.48584L29.4691 8.99563L12.763 12.0006Z"
					fill="#87AEFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L29.4489 8.89404L29.5382 9.40672L12.763 12.0008Z"
					fill="#86ADFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.5199 9.30371L29.5967 9.81927L12.763 12.0005Z"
					fill="#85ACFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L29.5814 9.7168L29.6448 10.2333L12.763 12.0008Z"
					fill="#84ACFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0006L29.6323 10.1294L29.6832 10.6478L12.763 12.0006Z"
					fill="#83ABFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L29.6736 10.5444L29.712 11.0638L12.763 12.0008Z"
					fill="#82AAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0007L29.7043 10.96L29.7292 11.4803L12.763 12.0007Z"
					fill="#81AAFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.001L29.7244 11.376L29.7369 11.8963L12.763 12.001Z"
					fill="#80A9FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0008L29.734 11.7925V12.3128L12.763 12.0008Z"
					fill="#7FA8FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.734 12.2088L29.7216 12.7292L12.763 12.0005Z"
					fill="#7EA7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.7244 12.6255L29.6985 13.1449L12.763 12.0005Z"
					fill="#7DA7FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.7043 13.0412L29.6659 13.5606L12.763 12.0005Z"
					fill="#7CA6FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.6736 13.4569L29.6227 13.9753L12.763 12.0005Z"
					fill="#7BA5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.6323 13.8707L29.5689 14.3882L12.763 12.0005Z"
					fill="#7AA5FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.5814 14.2845L29.5046 14.8L12.763 12.0005Z"
					fill="#79A4FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.5199 14.6963L29.4316 15.21L12.763 12.0005Z"
					fill="#78A3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.4489 15.1073L29.3471 15.618L12.763 12.0005Z"
					fill="#77A3FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.3673 15.5153L29.254 16.0241L12.763 12.0005Z"
					fill="#76A2FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.2771 15.9223L29.1503 16.4273L12.763 12.0005Z"
					fill="#75A1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.1753 16.3265L29.037 16.8277L12.763 12.0005Z"
					fill="#74A1FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L29.0639 16.7278L28.9132 17.2261L12.763 12.0005Z"
					fill="#73A0FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L28.9429 17.1263L28.7797 17.6207L12.763 12.0005Z"
					fill="#729FFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L28.8124 17.5218L28.6376 18.0124L12.763 12.0005Z"
					fill="#719FFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L28.6722 17.9145L28.485 18.4003L12.763 12.0005Z"
					fill="#709EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L28.5224 18.3023L28.3227 18.7843L12.763 12.0005Z"
					fill="#6F9DFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L28.3631 18.6873L28.1519 19.1635L12.763 12.0005Z"
					fill="#6E9DFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L28.1941 19.0685L27.9714 19.5389L12.763 12.0005Z"
					fill="#6D9CFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L28.0165 19.4448L27.7822 19.9104L12.763 12.0005Z"
					fill="#6C9BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L27.8283 19.8173L27.5835 20.2762L12.763 12.0005Z"
					fill="#6B9BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L27.6325 20.1841L27.3761 20.6382L12.763 12.0005Z"
					fill="#6A9AFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L27.427 20.547L27.1592 20.9934L12.763 12.0005Z"
					fill="#6999FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L27.2129 20.9041L26.9345 21.3438L12.763 12.0005Z"
					fill="#6899FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L26.9902 21.2565L26.7002 21.6894L12.763 12.0005Z"
					fill="#6798FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L26.7588 21.603L26.4593 22.0283L12.763 12.0005Z"
					fill="#6697FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L26.5188 21.9429L26.2087 22.3615L12.763 12.0005Z"
					fill="#6597FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L26.2711 22.278L25.9504 22.6879L12.763 12.0005Z"
					fill="#6496FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L26.0148 22.6063L25.6845 23.0086L12.763 12.0005Z"
					fill="#6395FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L25.7498 22.9279L25.4099 23.3225L12.763 12.0005Z"
					fill="#6294FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L25.4781 23.2438L25.1286 23.6297L12.763 12.0005Z"
					fill="#6194FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L25.1987 23.552L24.8396 23.9293L12.763 12.0005Z"
					fill="#6093FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L24.9107 23.8534L24.543 24.2221L12.763 12.0005Z"
					fill="#5F92FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L24.6159 24.1482L24.2396 24.5072L12.763 12.0005Z"
					fill="#5E92FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L24.3145 24.4362L23.9285 24.7857L12.763 12.0005Z"
					fill="#5D91FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L24.0063 24.7156L23.6117 25.0554L12.763 12.0005Z"
					fill="#5C90FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L23.6904 24.9873L23.2882 25.3175L12.763 12.0005Z"
					fill="#5B90FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L23.3688 25.2522L22.9579 25.5719L12.763 12.0005Z"
					fill="#5A8FFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L23.0405 25.5086L22.6219 25.8187L12.763 12.0005Z"
					fill="#598EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L22.7054 25.7563L22.2801 26.0558L12.763 12.0005Z"
					fill="#588EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L22.3656 25.9963L21.9316 26.2853L12.763 12.0005Z"
					fill="#578DFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L22.019 26.2277L21.5783 26.5061L12.763 12.0005Z"
					fill="#568CFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L21.6666 26.4504L21.2202 26.7183L12.763 12.0005Z"
					fill="#558CFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L21.3095 26.6645L20.8563 26.9208L12.763 12.0005Z"
					fill="#548BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L20.9466 26.87L20.4877 27.1148L12.763 12.0005Z"
					fill="#538AFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L20.5798 27.0658L20.1142 27.3001L12.763 12.0005Z"
					fill="#528AFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L20.2073 27.254L19.7369 27.4757L12.763 12.0005Z"
					fill="#5189FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L19.831 27.4316L19.3548 27.6428L12.763 12.0005Z"
					fill="#5088FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L19.4498 27.6006L18.9688 27.7993L12.763 12.0005Z"
					fill="#4F88FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L19.0649 27.7599L18.5791 27.9471L12.763 12.0005Z"
					fill="#4E87FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L18.677 27.9097L18.1864 28.0854L12.763 12.0005Z"
					fill="#4D86FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L18.2843 28.0499L17.7899 28.2131L12.763 12.0005Z"
					fill="#4C86FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L17.8888 28.1804L17.3905 28.3321L12.763 12.0005Z"
					fill="#4B85FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L17.4904 28.3014L16.9882 28.4406L12.763 12.0005Z"
					fill="#4A84FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L17.089 28.4128L16.5831 28.5395L12.763 12.0005Z"
					fill="#4984FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L16.6849 28.5145L16.177 28.6278L12.763 12.0005Z"
					fill="#4883FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L16.2778 28.6048L15.767 28.7066L12.763 12.0005Z"
					fill="#4782FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L15.8698 28.6864L15.3571 28.7757L12.763 12.0005Z"
					fill="#4681FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L15.4589 28.7574L14.9443 28.8342L12.763 12.0005Z"
					fill="#4581FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L15.047 28.8189L14.5305 28.8822L12.763 12.0005Z"
					fill="#4480FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L14.6332 28.8698L14.1157 28.9206L12.763 12.0005Z"
					fill="#437FFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L14.2194 28.911L13.7 28.9494L12.763 12.0005Z"
					fill="#427FFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L13.8037 28.9418L13.2834 28.9667L12.763 12.0005Z"
					fill="#417EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L13.388 28.9619L12.8667 28.9744L12.763 12.0005Z"
					fill="#407DFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L12.9713 28.9715H12.45L12.7629 12.0005Z"
					fill="#3F7DFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L12.5548 28.9715L12.0344 28.959L12.7631 12.0005Z"
					fill="#3E7CFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L12.138 28.9619L11.6177 28.936L12.763 12.0005Z"
					fill="#3D7BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L11.7223 28.9418L11.2029 28.9034L12.763 12.0005Z"
					fill="#3C7BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L11.3066 28.911L10.7882 28.8602L12.763 12.0005Z"
					fill="#3B7AFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L10.8919 28.8698L10.3754 28.8064L12.7631 12.0005Z"
					fill="#3A79FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L10.479 28.8189L9.96342 28.7421L12.763 12.0005Z"
					fill="#3979FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L10.0661 28.7574L9.55344 28.6691L12.7629 12.0005Z"
					fill="#3878FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L9.65619 28.6864L9.14544 28.5846L12.763 12.0005Z"
					fill="#3777FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L9.24826 28.6048L8.73943 28.4915L12.7631 12.0005Z"
					fill="#3677FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L8.84119 28.5145L8.3362 28.3878L12.763 12.0005Z"
					fill="#3576FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L8.43707 28.4128L7.93495 28.2745L12.7631 12.0005Z"
					fill="#3475FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L8.03555 28.3014L7.53728 28.1507L12.7629 12.0005Z"
					fill="#3375FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L7.63722 28.1804L7.14279 28.0172L12.763 12.0005Z"
					fill="#3274FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L7.24168 28.0499L6.75108 27.8751L12.763 12.0005Z"
					fill="#3173FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L6.84913 27.9097L6.36334 27.7225L12.7631 12.0005Z"
					fill="#3073FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L6.46017 27.7599L5.97918 27.5602L12.763 12.0005Z"
					fill="#2F72FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L6.07502 27.6006L5.59978 27.3893L12.7628 12.0005Z"
					fill="#2E71FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L5.6948 27.4316L5.22437 27.2089L12.7628 12.0005Z"
					fill="#2D71FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L5.31854 27.254L4.85291 27.0197L12.7629 12.0005Z"
					fill="#2C70FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L4.94634 27.0658L4.48743 26.821L12.7632 12.0005Z"
					fill="#2B6FFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L4.57922 26.8699L4.12511 26.6136L12.7628 12.0005Z"
					fill="#2A6EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L4.21638 26.6645L3.76995 26.3966L12.7629 12.0005Z"
					fill="#296EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L3.85943 26.4504L3.41876 26.172L12.7631 12.0005Z"
					fill="#286DFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L3.50692 26.2277L3.07393 25.9377L12.7629 12.0005Z"
					fill="#276CFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L3.16036 25.9963L2.73505 25.6968L12.7629 12.0005Z"
					fill="#266CFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L2.82073 25.7563L2.40215 25.4462L12.7631 12.0005Z"
					fill="#256BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L2.4853 25.5086L2.07439 25.1879L12.7628 12.0005Z"
					fill="#246AFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L2.15726 25.2522L1.75499 24.922L12.7631 12.0005Z"
					fill="#236AFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L1.83573 24.9873L1.44114 24.6474L12.7632 12.0005Z"
					fill="#2269FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L1.5196 24.7156L1.13365 24.3661L12.7629 12.0005Z"
					fill="#2168FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L1.21142 24.4362L0.834114 24.0771L12.7629 12.0005Z"
					fill="#2068FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L0.909985 24.1482L0.541321 23.7805L12.7629 12.0005Z"
					fill="#1F67FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L0.614184 23.8534L0.256081 23.4771L12.7628 12.0005Z"
					fill="#1E66FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L0.327453 23.552L-0.0220108 23.166L12.7632 12.0005Z"
					fill="#1D66FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L0.0481067 23.2438L-0.291756 22.8492L12.7632 12.0005Z"
					fill="#1C65FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-0.223693 22.9279L-0.553955 22.5257L12.7631 12.0005Z"
					fill="#1B64FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L-0.488908 22.6063L-0.808609 22.1954L12.7628 12.0005Z"
					fill="#1A64FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-0.745213 22.278L-1.05531 21.8594L12.7629 12.0005Z"
					fill="#1963FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L-0.992948 21.9429L-1.29249 21.5176L12.7628 12.0005Z"
					fill="#1862FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-1.23273 21.603L-1.52171 21.1691L12.7631 12.0005Z"
					fill="#1762FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-1.46417 21.2565L-1.74259 20.8158L12.763 12.0005Z"
					fill="#1661FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-1.68688 20.9041L-1.95473 20.4577L12.763 12.0005Z"
					fill="#1560FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-1.90101 20.547L-2.15735 20.0938L12.763 12.0005Z"
					fill="#1460FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L-2.10624 20.1841L-2.35201 19.7252L12.7632 12.0005Z"
					fill="#135FFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L-2.30345 19.8173L-2.53674 19.3517L12.7628 12.0005Z"
					fill="#125EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-2.49056 19.4448L-2.71233 18.9744L12.7629 12.0005Z"
					fill="#115EFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-2.66797 19.0685L-2.87919 18.5923L12.7631 12.0005Z"
					fill="#105DFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-2.83722 18.6873L-3.03691 18.2063L12.7629 12.0005Z"
					fill="#0F5CFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L-2.99629 18.3023L-3.1835 17.8166L12.7632 12.0005Z"
					fill="#0E5BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-3.14606 17.9145L-3.32175 17.4239L12.7631 12.0005Z"
					fill="#0D5BFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-3.28743 17.5218L-3.44968 17.0274L12.7629 12.0005Z"
					fill="#0C5AFF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L-3.41773 17.1263L-3.56846 16.628L12.7632 12.0005Z"
					fill="#0B59FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7632 12.0005L-3.53866 16.7278L-3.67691 16.2257L12.7632 12.0005Z"
					fill="#0A59FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7628 12.0005L-3.6495 16.3265L-3.77623 15.8206L12.7628 12.0005Z"
					fill="#0958FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-3.75114 15.9223L-3.86443 15.4145L12.7629 12.0005Z"
					fill="#0857FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-3.84228 15.5153L-3.94308 15.0045L12.763 12.0005Z"
					fill="#0757FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.763 12.0005L-3.92292 15.1073L-4.01221 14.5946L12.763 12.0005Z"
					fill="#0656FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-3.9938 14.6963L-4.07061 14.1818L12.7631 12.0005Z"
					fill="#0555FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-4.05531 14.2845L-4.11868 13.768L12.7631 12.0005Z"
					fill="#0455FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-4.10633 13.8707L-4.15721 13.3532L12.7629 12.0005Z"
					fill="#0354FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-4.14742 13.4569L-4.18582 12.9375L12.7631 12.0005Z"
					fill="#0253FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7629 12.0005L-4.17834 13.0412L-4.2033 12.5208L12.7629 12.0005Z"
					fill="#0153FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0005L-4.19837 12.6255L-4.21085 12.1042L12.7631 12.0005Z"
					fill="#0052FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.7631 12.0008L-4.20886 12.2091V11.7925L12.7631 12.0008Z"
					fill="white"
				/>
			</g>
			<path
				d="M94.7345 22.0105C94.6601 22.0161 94.5856 22.0263 94.5108 22.0263C90.4144 22.027 86.318 22.027 82.2217 22.027H82.0043V1.99707H94.6067V4.15486H84.6313V10.6121H93.3264V12.7853H84.6321V19.8497C84.7212 19.8497 84.8062 19.8497 84.8908 19.8497C88.0916 19.8497 91.2923 19.8497 94.4927 19.8504C94.5732 19.8504 94.6533 19.8636 94.7337 19.8704V22.0101L94.7345 22.0105Z"
				fill="currentColor"
			/>
			<path
				d="M39.4528 11.526C40.1074 11.7185 40.7079 11.9926 41.226 12.4235C42.1817 13.2183 42.7108 14.2508 42.8596 15.4716C42.9965 16.5935 42.8946 17.6982 42.4457 18.7468C41.8185 20.212 40.6695 21.0982 39.1904 21.5975C38.2309 21.9216 37.2375 22.0262 36.2306 22.0266C33.9758 22.0277 31.7207 22.0269 29.4659 22.0269C29.4102 22.0269 29.3542 22.0269 29.2828 22.0269V1.99998C29.3207 1.99434 29.3677 1.98193 29.4144 1.98193C31.8132 1.98795 34.2123 1.97817 36.6107 2.00976C37.6421 2.02329 38.652 2.22332 39.6032 2.6463C41.1711 3.34338 42.0396 4.57098 42.3006 6.24375C42.4291 7.06641 42.4438 7.88982 42.1795 8.69405C41.8576 9.6735 41.2188 10.4093 40.3744 10.9755C40.0849 11.1696 39.7754 11.3339 39.4525 11.5252L39.4528 11.526ZM31.9045 19.8391C31.9711 19.8436 32.0203 19.8496 32.0692 19.8496C33.4844 19.8496 34.9 19.8594 36.3152 19.8436C36.7318 19.8391 37.1567 19.803 37.5624 19.7124C38.7813 19.4409 39.6713 18.7551 40.0864 17.5463C40.3488 16.7815 40.3311 15.9957 40.1725 15.2122C39.9785 14.253 39.4682 13.5281 38.5678 13.1025C38.0278 12.8476 37.453 12.713 36.8615 12.7051C35.2463 12.6837 33.6303 12.6867 32.0147 12.6814C31.979 12.6814 31.9432 12.6942 31.9045 12.7017V19.8391ZM31.909 10.5277C31.9684 10.5326 32.0173 10.5398 32.0658 10.5398C33.3618 10.5398 34.6582 10.5401 35.9543 10.539C36.451 10.5386 36.9435 10.4905 37.4169 10.3337C38.4505 9.99121 39.2479 9.38812 39.5788 8.3019C39.7649 7.6913 39.7461 7.06828 39.642 6.44452C39.4837 5.49854 38.9558 4.85861 38.0602 4.51571C37.5349 4.31456 36.9894 4.19311 36.431 4.18484C34.9598 4.16303 33.4886 4.16567 32.0173 4.16003C31.9812 4.16003 31.9455 4.17206 31.909 4.17845V10.5281V10.5277Z"
				fill="currentColor"
			/>
			<path
				d="M78.5408 7.75062H75.8973C75.8683 7.4675 75.8574 7.19228 75.8097 6.92382C75.5472 5.45259 74.6712 4.51337 73.2503 4.10918C71.8377 3.70763 70.4248 3.7347 69.0622 4.33252C67.4154 5.05479 67.0762 6.80576 67.4443 7.94463C67.6376 8.5432 68.0508 8.9722 68.5486 9.32826C69.2998 9.8663 70.1514 10.1975 71.0196 10.4848C71.9629 10.7969 72.9225 11.0619 73.8583 11.3943C74.9904 11.7962 76.0537 12.3365 76.956 13.1513C77.9956 14.0898 78.5277 15.2595 78.5795 16.6562C78.6258 17.8996 78.3446 19.0393 77.5403 20.0161C76.7545 20.9703 75.733 21.5817 74.5599 21.9107C71.6746 22.7198 68.9238 22.4175 66.4021 20.7368C64.8677 19.7141 64.0965 18.229 64.0093 16.3897C64.0071 16.3404 64.0093 16.2908 64.0093 16.225H66.645C66.6931 16.5581 66.7145 16.8912 66.792 17.2105C67.0732 18.3666 67.8327 19.119 68.8836 19.6104C69.7758 20.0273 70.7237 20.1586 71.6982 20.148C72.5502 20.139 73.3778 20.0153 74.17 19.6777C76.4552 18.7042 76.2481 15.8351 75.0554 14.8011C74.6377 14.4391 74.1655 14.1646 73.6545 13.9788C72.598 13.5946 71.5302 13.2415 70.4624 12.8892C69.1084 12.4429 67.8064 11.8993 66.6367 11.0616C64.0213 9.18915 64.0191 5.34242 66.4089 3.37263C67.5695 2.41574 68.887 1.91831 70.3612 1.77506C71.6964 1.64534 73.0157 1.71565 74.2929 2.15518C76.1913 2.80902 77.5715 4.01594 78.2506 5.94363C78.4532 6.51926 78.5517 7.11482 78.5416 7.75024L78.5408 7.75062Z"
				fill="currentColor"
			/>
			<path
				d="M62.3499 22.0853H62.0232C61.2904 22.0853 60.5576 22.082 59.8251 22.0883C59.6905 22.0895 59.6311 22.0466 59.5849 21.9195C58.992 20.2813 58.3908 18.6462 57.799 17.0073C57.7501 16.8715 57.6903 16.8268 57.5482 16.8268C54.8617 16.8309 52.1749 16.8309 49.4885 16.8264C49.3502 16.8264 49.2956 16.8749 49.2513 16.999C48.6704 18.6349 48.0823 20.2682 47.5029 21.9045C47.4559 22.0376 47.4003 22.0898 47.2548 22.0883C46.466 22.0812 45.6768 22.0853 44.8876 22.0846C44.8323 22.0846 44.777 22.0789 44.6973 22.0744C44.7522 21.9312 44.8007 21.8045 44.8488 21.6781C47.3243 15.2036 49.8002 8.72915 52.2712 2.25277C52.3351 2.08546 52.4163 2.03094 52.5938 2.03433C53.2198 2.04561 53.8462 2.04523 54.4722 2.03433C54.6373 2.03132 54.7095 2.0832 54.7681 2.23698C56.7108 7.33085 58.6585 12.4228 60.6057 17.5148C61.161 18.9673 61.716 20.4197 62.2709 21.8722C62.2931 21.9297 62.3138 21.988 62.3495 22.085L62.3499 22.0853ZM53.5229 5.30617C52.3772 8.43814 51.2455 11.5314 50.1104 14.634H56.9353C55.7968 11.5224 54.667 8.43362 53.5229 5.30617Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_873_96004">
				<rect
					width="93.9723"
					height="24"
					fill="currentColor"
					transform="translate(0.762207)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default BaseLogo
